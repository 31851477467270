html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight: bold;
  font-size: 3.6em;
  margin: 20px 0px;
  color: #282828;
}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: 2.6em;
  }
  h2 {
    font-size: 2.6em;
  }
  h3 {
    font-size: 0.8em;
  }
  h4 {
    font-size: 0.6em;
  }
}

h2 {
  font-weight: bold;
  font-size: 2.8em;
  color: #282828;
}

h3 {
  font-size: 1.4em;
  font-weight: 300;
  margin: 10px 0px;
  color: #282828;
}

h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 10px 0px;
  color: #363636;
}

p {
  margin: 0px;
}

button {
  cursor: pointer;
}

/*REACT CALENDER*/

.react-calendar__tile--active {
  background-color: #fe6244 !important;
}

.react-calendar {
  background-color: white;
  border-radius: 6px;
  padding: 4px;
}

.react-calendar__tile {
  background-color: white;
  background-color: white;
  border: 1px solid #ffdffd;
  padding: 20px 0px;
  cursor: pointer;
}

.react-calendar__navigation__arrow {
  background-color: white;
  padding: 2px 10px;
  font-size: 1em;
}

.react-calendar__navigation__label {
  background-color: white;
}

.react-calendar__navigation {
  display: flex;
  width: 160px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background-color: #8a8a8a;
}

*:disabled {
  background-color: #8a8a8a;
  color: black;
}
